import logo from './logo.svg';
import './App.scss';
import QuizContainer from './components/QuizContainer';
import PointsBar from './components/PointsBar';
import { useState } from 'react';
import { readFromStorage, writeToStorage } from './utils/storageUtil';

const POINTS_KEY = "POINTS";

function App() {
  const [totalPoints, setPoints] = useState(readFromStorage(POINTS_KEY) ?? 0);
  const [quizStarted, setQuizStarted] = useState(false);

  const updatePoints = (points) => {
    setPoints(points);
    writeToStorage(POINTS_KEY, points);
  }

  const renderInitialState = () => {
    return (
      <div className="app-initial-state">
        <h1>
          Bingo Sucks.
        </h1>

        <br/>
        <br/>

        <div className="start-quiz-mobile">
          <h4>Use a bigger screen to enter the Bingo Christmas Quiz Experience.</h4>
        </div>

        <div className="start-quiz">
          <h4>How well do you know Bingo?<br/>Start the quiz so Bingo can earn Christmas presents.</h4>
          <button onClick={setQuizStarted.bind(this, true)}>Start Quiz</button>
        </div>
      </div>
    )
  };

  const renderQuiz = () => {
    return (
      <div>
        <PointsBar totalPoints={totalPoints} maxPoints={260} markers={[65, 130, 195, 260]} />
        <QuizContainer totalPoints={totalPoints} setPoints={updatePoints} />
      </div>
    )
  }

  return (
    <div className="App">
      <header className="App-header">
        <p className="logo">
          <span className="logo-suck">suck</span><span className="logo-it">it</span><span className="logo-dot">.</span><span className="logo-bingo">bingo</span>
        </p>
      </header>

      { quizStarted ? renderQuiz() : renderInitialState() }
    </div>
  );
}

export default App;
