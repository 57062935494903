import QuestionContainer from "./QuestionContainer";
import CodeInput from "./CodeInput";
import RulesList from "./RulesList";
import { COMPLETED_QUESTIONS_KEY } from "./QuestionContainer";
import { lookupQuestionByCode } from "../utils/questionLookupUtil";
import { useState } from "react";
import { readFromStorage } from "../utils/storageUtil";

const ALREADY_ANSWERED_TEXT = "Stop trying to answer the same question twice. I won't give you more points.";

function QuizContainer({ totalPoints, setPoints }) {
    const [activeQuestion, setActiveQuestion] = useState(null);

    const codeInputUpdated = (event) => {
        const text = event.target?.value;
        if (text) {
            const questionFound = lookupQuestionByCode(text.toUpperCase());
            if (questionFound) {
                const answeredQuestions = readFromStorage(COMPLETED_QUESTIONS_KEY) ?? [];
                if (answeredQuestions.find((code) => code === text.toUpperCase())) {
                    alert(ALREADY_ANSWERED_TEXT);
                } else {
                    setActiveQuestion(questionFound);
                }
                event.target.value = "";
            } else if (activeQuestion) {
                setActiveQuestion(null);
            }
        }
    }

    return (
        <div>
            { !activeQuestion && <CodeInput inputUpdated={codeInputUpdated} /> }
            { !activeQuestion && <RulesList /> }
            { activeQuestion && <QuestionContainer question={activeQuestion} totalPoints={totalPoints} setPoints={setPoints} setActiveQuestion={setActiveQuestion} />  }
        </div>
      );
}

export default QuizContainer;