import './PointsBar.scss';

export default function PointsBar({totalPoints, maxPoints, markers}) {
    const markerDivs = markers.map(mark => {
        const width = (mark / maxPoints) * 100;
        const divStyle = {
            width: `${width}%`
          };
        return (<div style={divStyle} className="marker-div">
            <p>{mark}</p>
        </div>);
    });

    const pointsDivStyle = {
        width: `${totalPoints/maxPoints * 100}%`
    };

    return (
        <div className="points-bar">
            <h3>{totalPoints} Points</h3>
            { markerDivs }
            <div className="points-div" style={pointsDivStyle}></div>
        </div>
    );
}