import './AnswerResult.scss';

function AnswerResult({wasCorrect, pointsChange}) {
    return (
        <div className="answer-result-background">
            <div className={wasCorrect ? 'answer-result answer-result-correct' : 'answer-result answer-result-incorrect'}>
                <h3>
                    {wasCorrect ? 'Correct!' : 'Wrong!' }
                </h3>
                <h4>
                    { `You earned ${pointsChange} points` }
                </h4>
                { !wasCorrect && (
                    <h5>
                        Do better next time.
                    </h5>
                )}
            </div>
        </div>
      );
}

export default AnswerResult;