const QUIZ_STATE = "QUIZ_STATE";

export const writeToStorage = (key, val) => {
    try {
        const itemsToWrite = JSON.parse(localStorage.getItem(QUIZ_STATE)) ?? {};
        itemsToWrite[key] = val;
        return localStorage.setItem(QUIZ_STATE, JSON.stringify(itemsToWrite));
    } catch (e) {
        console.error(e);
    }
}

export const readFromStorage = (key) => {
    try {
        const parsed = JSON.parse(localStorage.getItem(QUIZ_STATE)) ?? {};
        return parsed[key] ?? null;
    } catch (e) {
        console.error(e);
    }
}