import questionConfig from "../config/questionConfig";

const lookupQuestionByCode = (code) => {
    /*
    let total = 0;
    let codes = [];
    questionConfig.questions.forEach(q => {
        total += q.points;
        codes.push(q.code);
    })
    console.log('codes', codes);
    console.log('total points', total);*/
    return questionConfig.questions.find((q) => q.code === code);
};

export { lookupQuestionByCode };