import './QuestionContainer.scss';
import { readFromStorage, writeToStorage } from "../utils/storageUtil";
import QuestionOption from "./QuestionOption";
import AnswerResult from "./AnswerResult";
import { useState } from 'react';

export const COMPLETED_QUESTIONS_KEY = "COMPLETED_QUESTIONS";

function QuestionContainer({ question, totalPoints, setPoints, setActiveQuestion }) {
  const { questionText, options, answer, points, code } = question;
  const [displayResult, setDisplayResult] = useState(false);
  const [wasAnswerCorrect, setWasAnswerCorrect] = useState(true);
  const [questionPoints, setQuestionPoints] = useState(0);

  const optionSelectedHandler = (optionClicked) => {
    let pointsChange = (optionClicked.letter !== answer) ? -(points / 2) : points;
    setPoints(totalPoints + pointsChange);

    markQuestionAsDone(code);
    showResult(optionClicked.letter === answer, pointsChange);

    setTimeout(function() {
      setActiveQuestion(null);
      hideResult();
    }, 4000);
  }

  const markQuestionAsDone = (code) => {
    const alreadyAnswered = readFromStorage(COMPLETED_QUESTIONS_KEY) ?? [];
    alreadyAnswered.push(code.toUpperCase());
    writeToStorage(COMPLETED_QUESTIONS_KEY, alreadyAnswered);
  }

  const showResult = (isCorrect, points) => {
    setQuestionPoints(points);
    setWasAnswerCorrect(isCorrect);
    setDisplayResult(true);
  }

  const hideResult = () => {
    setDisplayResult(false);
  }

  const optionComponents = options.map(opt =>
    <QuestionOption option={opt} key={opt.letter} onClick={optionSelectedHandler} />
  );

  return (
    <div className="question-container">
      <h3>{ questionText }</h3>
      { displayResult && (
        <AnswerResult wasCorrect={wasAnswerCorrect} pointsChange={questionPoints} />
      )}

      { optionComponents }
    </div>
  );
}

export default QuestionContainer;
