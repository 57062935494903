export default {
    questions: [
        {
            questionText: "Bingo's Corvette is not currently running. Why not?",
            code: "LS7VLV", // done
            points: 7,
            options: [
              {
                letter: "A",
                text: "It dropped a valve and has never really been working correctly since"
              },
              {
                letter: "B",
                text: "$16,000 is apparently not enough money to fix a broken LS"
              },
              {
                letter: "C",
                text: "Bingo is generally incomptent"
              },
              {
                letter: "D",
                text: "All of the above"
              }
            ],
            answer: "D"
        },
        {
            questionText: "Which of the following has Bingo not used as a Halo player name?",
            code: "GINGER", // done
            points: 6,
            options: [
              {
                letter: "A",
                text: "Bingo"
              },
              {
                letter: "B",
                text: "PumpkinPubes"
              },
              {
                letter: "C",
                text: "WorldAbstinenceChampion"
              },
              {
                letter: "D",
                text: "ExtraChromosome"
              }
            ],
            answer: "C"
        },
        {
            questionText: "What is the most number of vehicles vehicles Bingo has owned at a single point?",
            code: "5UCK1T", // done
            points: 8,
            options: [
              {
                letter: "A",
                text: "5"
              },
              {
                letter: "B",
                text: "7"
              },
              {
                letter: "C",
                text: "9"
              },
              {
                letter: "D",
                text: "11"
              }
            ],
            answer: "C"
        },
        {
            questionText: "True or false. Bingo's mother cried profusely when she realized he was going to be a boy.",
            code: "KER4SD", // done
            points: 4,
            options: [
              {
                letter: "A",
                text: "True"
              },
              {
                letter: "B",
                text: "False"
              }
            ],
            answer: "A"
        },
        {
            questionText: "How many hours has Bingo spent playing the video game PUBG?",
            code: "TRDNGGT", // done
            points: 9,
            options: [
              {
                letter: "A",
                text: "158"
              },
              {
                letter: "B",
                text: "690"
              },
              {
                letter: "C",
                text: "1,053"
              },
              {
                letter: "D",
                text: "3,046"
              }
            ],
            answer: "D"
        },
        {
            questionText: "How long did Bingo spend destroying the pit toilet at the entrance to Canyonlands National Park the morning of October 14, 2022?",
            code: "ABG4LIFE", // done - rock
            points: 10,
            options: [
              {
                letter: "A",
                text: "8 minutes"
              },
              {
                letter: "B",
                text: "12 minutes"
              },
              {
                letter: "C",
                text: "28 minutes"
              },
              {
                letter: "D",
                text: "57 minutes"
              }
            ],
            answer: "C"
        },
        {
            questionText: "Bingo's home has hosted an infestation of what?",
            code: "QSDFGH", // done - stick 
            points: 3,
            options: [
              {
                letter: "A",
                text: "Bats"
              },
              {
                letter: "B",
                text: "Bedbugs"
              },
              {
                letter: "C",
                text: "Many nice, well maintained vehicles"
              },
              {
                letter: "D",
                text: "AIDS"
              }
            ],
            answer: "A"
        },
        {
          questionText: "If you can get Bingo to admit that he sucks, Bingo receives 10 points.",
          code: "ML23FB", // done
          points: 10,
          options: [
            {
              letter: "A",
              text: "Yes, Bingo admits he sucks."
            },
            {
              letter: "B",
              text: "No, Bingo is oblivious to reality."
            },
          ],
          answer: "A"
      },
      {
        questionText: "Bingo's infamous tapesty has changed hands in many ways. Which of the following is not one?",
        code: "WRSTE39", // done
        points: 8,
        options: [
          {
            letter: "A",
            text: "A fake craigslist ad."
          },
          {
            letter: "B",
            text: "In a storage compartment of his corvette (the one that is not currently running)."
          },
          {
            letter: "C",
            text: "Inside of a dog bed."
          },
          {
            letter: "D",
            text: "Waiting in a bed, under the sheets."
          }
        ],
        answer: "A"
    },
    {
      questionText: "How many times can you register cars in Michigan in one year before needing to register as an automotive dealer?",
      code: "DMB4SS", // done
      points: 10,
      options: [
        {
          letter: "A",
          text: "4"
        },
        {
          letter: "B",
          text: "5"
        },
        {
          letter: "C",
          text: "6"
        },
        {
          letter: "D",
          text: "7"
        }
      ],
      answer: "B"
    },
    {
      questionText: "After Bingo launched a Ford Explorer over 3 feet into the air at the Tennessee gambler, how many lugs remained intact on the front left hub after landing?",
      code: "NM6DHP", // DONE
      points: 8,
      options: [
        {
          letter: "A",
          text: "0"
        },
        {
          letter: "B",
          text: "1"
        },
        {
          letter: "C",
          text: "2"
        },
        {
          letter: "D",
          text: "4"
        }
      ],
      answer: "C"
    },
    {
      questionText: "Which of the following makes of vehicle has Bingo never owned?",
      code: "F44SAM", // done
      points: 7,
      options: [
        {
          letter: "A",
          text: "Suzuki"
        },
        {
          letter: "B",
          text: "Honda"
        },
        {
          letter: "C",
          text: "Jaguar"
        },
        {
          letter: "D",
          text: "Toyota"
        }
      ],
      answer: "B"
    },
    {
      questionText: "In 2021, which award did Bingo receive?",
      code: "ABSTNCE", // done
      points: 6,
      options: [
        {
          letter: "A",
          text: "Worst E32 BMW"
        },
        {
          letter: "B",
          text: "World abstinence champion"
        },
        {
          letter: "C",
          text: "3rd place - shaft rider"
        },
        {
          letter: "D",
          text: "All of the above"
        }
      ],
      answer: "D"
    },
    {
      questionText: "Jeep Wrangler 4xEs have been recalled due to fire risk. What role did Bingo play in the development of this vehicle?",
      code: "U5UCK", // done
      points: 10,
      options: [
        {
          letter: "A",
          text: "Associate Test Driver"
        },
        {
          letter: "B",
          text: "ABS calibrator"
        },
        {
          letter: "C",
          text: "Thermal Engineer"
        },
        {
          letter: "D",
          text: "None"
        }
      ],
      answer: "C"
    },
    {
      questionText: "On average, how long does it take Bingo to sunburn?",
      code: "B1NGO", // done
      points: 5,
      options: [
        {
          letter: "A",
          text: "6 minutes"
        },
        {
          letter: "B",
          text: "11 minutes"
        },
        {
          letter: "C",
          text: "20 minutes"
        },
        {
          letter: "D",
          text: "He burns as soon as he thinks about sunlight."
        }
      ],
      answer: "D"
    },
    {
      questionText: "How many years has Bingo received an absurdly high number of presents at Christmas?",
      code: "CVBNJ2", // done
      points: 4,
      options: [
        {
          letter: "A",
          text: "14 years"
        },
        {
          letter: "B",
          text: "11 years"
        },
        {
          letter: "C",
          text: "20 years"
        },
        {
          letter: "D",
          text: "9 years"
        }
      ],
      answer: "A"
    },
    {
      questionText: "Which of the following hazardous gifts has Bingo received at Christmas?",
      code: "SAMUEL", // done
      points: 7,
      options: [
        {
          letter: "A",
          text: "The stool tool (a long potato masher with razor blades)"
        },
        {
          letter: "B",
          text: "A moldy piece of cabbage"
        },
        {
          letter: "C",
          text: "His cousin's baby teeth"
        },
        {
          letter: "D",
          text: "All of the above"
        }
      ],
      answer: "D"
    },
    {
      questionText: "Bingo has spotted approximately how many farms on his highest performing road trip?",
      code: "2MNYFRMS", // done
      points: 10,
      options: [
        {
          letter: "A",
          text: "70"
        },
        {
          letter: "B",
          text: "100"
        },
        {
          letter: "C",
          text: "130"
        },
        {
          letter: "D",
          text: "310"
        }
      ],
      answer: "C"
    },
    {
      questionText: "In response to unbearable harrassment, Bingo hurled what heavt object at his older brother's head, causing profuse bleeding?",
      code: "QM48X2", // done
      points: 8,
      options: [
        {
          letter: "A",
          text: "A brick"
        },
        {
          letter: "B",
          text: "A waterlogged boat toy"
        },
        {
          letter: "C",
          text: "A baseball bat"
        },
        {
          letter: "D",
          text: "A machete"
        }
      ],
      answer: "B"
    },
    {
      questionText: "Bingo has long maintained an online relationship with a special partner in Taiwan.  What is this person's name?",
      code: "ZSDFGJ", // done
      points: 10,
      options: [
        {
          letter: "A",
          text: "Kathryn"
        },
        {
          letter: "B",
          text: "Ming"
        },
        {
          letter: "C",
          text: "Christineth"
        },
        {
          letter: "D",
          text: "Hank"
        }
      ],
      answer: "D"
    },
    {
      questionText: "Bingo has ruined how many transmissions in his E32 BMW?",
      code: "POSBMW", // done
      points: 6,
      options: [
        {
          letter: "A",
          text: "0"
        },
        {
          letter: "B",
          text: "1"
        },
        {
          letter: "C",
          text: "2"
        },
        {
          letter: "D",
          text: "3"
        }
      ],
      answer: "C"
    },
    {
      questionText: "What was Bingo's first job?",
      code: "B76VCL", // done
      points: 3,
      options: [
        {
          letter: "A",
          text: "Steak n Shake Waiter"
        },
        {
          letter: "B",
          text: "$5 services behind the Wendy's dumpster"
        },
        {
          letter: "C",
          text: "Penn Station Fry Cook"
        },
        {
          letter: "D",
          text: "Medical Experimentee"
        }
      ],
      answer: "C"
    },
    {
      questionText: "Bingo once vehemently argued that which of the following animals was 'man eating'?",
      code: "BIKJHG", // done
      points: 9,
      options: [
        {
          letter: "A",
          text: "Armadillo"
        },
        {
          letter: "B",
          text: "Deer"
        },
        {
          letter: "C",
          text: "Orangutan"
        },
        {
          letter: "D",
          text: "Elk"
        }
      ],
      answer: "D"
    },
    {
      questionText: "Bingo's style of speech is best described as?",
      code: "UJNNKU", // done
      points: 10,
      options: [
        {
          letter: "A",
          text: "Abrupt"
        },
        {
          letter: "B",
          text: "Abrasive"
        },
        {
          letter: "C",
          text: "Absolute"
        },
        {
          letter: "D",
          text: "All of the above"
        }
      ],
      answer: "D"
    },
    {
      questionText: "GM LS7s are widely known for:",
      code: "AQ4OKU", // DONE
      points: 8,
      options: [
        {
          letter: "A",
          text: "Making over 600 wheel horse power naturally aspirated"
        },
        {
          letter: "B",
          text: "Introducing a new era of V8 performance"
        },
        {
          letter: "C",
          text: "Dropping valves at 45k miles"
        },
        {
          letter: "D",
          text: "Being readily available for cheap engine swaps"
        }
      ],
      answer: "C"
    },
    {
      questionText: "Bingo currently works for which multi-national corporation:",
      code: "BCFDSA", // DONE
      points: 6,
      options: [
        {
          letter: "A",
          text: "Ozempic"
        },
        {
          letter: "B",
          text: "Stellantis"
        },
        {
          letter: "C",
          text: "Celebrex"
        },
        {
          letter: "D",
          text: "Humira"
        }
      ],
      answer: "B"
    },
    {
      questionText: "What is Bingo's signature (and entirely ineffective) pickup line?",
      code: "AMYR5RF",// DONE
      points: 7,
      options: [
        {
          letter: "A",
          text: "Want to hear my rod knock?"
        },
        {
          letter: "B",
          text: "I am like Batman, except instead of cool gadgets I just have bats."
        },
        {
          letter: "C",
          text: "Ey yo girl, what's gucci?"
        },
        {
          letter: "D",
          text: "I'm the world abstinence champion. Want to test my record?"
        }
      ],
      answer: "C"
    },
    {
      questionText: "Bingo's main guidelines for dating are:",
      code: "WF6AKM", // done
      points: 5,
      options: [
        {
          letter: "A",
          text: "No one over 50."
        },
        {
          letter: "B",
          text: "Women only, usually, but it does depend a little."
        },
        {
          letter: "C",
          text: "The DENNIS system."
        },
        {
          letter: "D",
          text: "Warm and has a pulse"
        }
      ],
      answer: "D"
    },
    {
      questionText: "What is Bingo's greatest athletic accomplishment?",
      code: "P3N1535",  // DONE
      points: 7,
      options: [
        {
          letter: "A",
          text: "Eating over 7 pounds of Chick Fil A in one meal."
        },
        {
          letter: "B",
          text: "Running through the woods faster than some other people."
        },
        {
          letter: "C",
          text: "Taking a dump larger than a canteloupe."
        },
        {
          letter: "D",
          text: "3rd place - shaft rider."
        }
      ],
      answer: "C"
    },
    {
      questionText: "During a 2018 game of road trip Bingo, Bingo spotted a space port in what unexpected state, clinching victory?",
      code: "NM98L2", // done
      points: 5,
      options: [
        {
          letter: "A",
          text: "Texas"
        },
        {
          letter: "B",
          text: "Oklahoma"
        },
        {
          letter: "C",
          text: "Arkansas"
        },
        {
          letter: "D",
          text: "Nevada"
        }
      ],
      answer: "B"
    },
    {
      questionText: "Bingo is:",
      code: "MKS8VN", // done
      points: 8,
      options: [
        {
          letter: "A",
          text: "A dog in Minnesota"
        },
        {
          letter: "B",
          text: "A robot vacuum that sucks"
        },
        {
          letter: "C",
          text: "A ginger"
        },
        {
          letter: "D",
          text: "All of the above"
        }
      ],
      answer: "D"
    },
    {
      questionText: "Bingo's face is best described as",
      code: "KM44FP", // done
      points: 9,
      options: [
        {
          letter: "A",
          text: "Contorted"
        },
        {
          letter: "B",
          text: "Mediocre"
        },
        {
          letter: "C",
          text: "Startling"
        },
        {
          letter: "D",
          text: "Passable"
        }
      ],
      answer: "C"
    },
    {
      questionText: "Following a regrettable vomit situation, which food can Bingo no longer eat?",
      code: "ANU535", // done
      points: 6,
      options: [
        {
          letter: "A",
          text: "$5 footlongs"
        },
        {
          letter: "B",
          text: "Pineapple"
        },
        {
          letter: "C",
          text: "Spam"
        },
        {
          letter: "D",
          text: "8+ inch Zucchini"
        }
      ],
      answer: "B"
    },
    {
      questionText: "Which of the following did Bingo not put in his mouth in China?",
      code: "LNGVTY", // done
      points: 6,
      options: [
        {
          letter: "A",
          text: "Shark Meat"
        },
        {
          letter: "B",
          text: "Trachea"
        },
        {
          letter: "C",
          text: "Stick squid"
        },
        {
          letter: "D",
          text: "Great Value Beer"
        }
      ],
      answer: "A"
    },
    {
      questionText: "Where did Bingo terribly damage the transmission in a rental car?",
      code: "JD9EMX", // done
      points: 4,
      options: [
        {
          letter: "A",
          text: "Colorado"
        },
        {
          letter: "B",
          text: "Scotland"
        },
        {
          letter: "C",
          text: "Canada"
        },
        {
          letter: "D",
          text: "Nevada"
        }
      ],
      answer: "B"
    },
    {
      questionText: "Due to an expired passport, Bingo was unable to travel to Mexico for his cousin's wedding. What experiences did substitute Bingo have in his place?",
      code: "M3X1C0", // done
      points: 7,
      options: [
        {
          letter: "A",
          text: "Get a sunburn"
        },
        {
          letter: "B",
          text: "Get mauled by an iguana"
        },
        {
          letter: "C",
          text: "Make friends at a hibachi restaurant"
        },
        {
          letter: "D",
          text: "All of the above"
        }
      ],
      answer: "D"
    },

    {
      questionText: "One Christmas at Ashleigh's dad's house, Bingo broke what item, requiring a Christmas day trip to Home Depot?",
      code: "LETITSNOW", // done
      points: 5,
      options: [
        {
          letter: "A",
          text: "A sink"
        },
        {
          letter: "B",
          text: "A toilet"
        },
        {
          letter: "C",
          text: "A plunger"
        },
        {
          letter: "D",
          text: "A refrigerator"
        }
      ],
      answer: "C"
    },
    {
      questionText: "What is Bingo's preferred airline?",
      code: "WRSTSON", // done
      points: 5,
      options: [
        {
          letter: "A",
          text: "Delta"
        },
        {
          letter: "B",
          text: "Spirit"
        },
        {
          letter: "C",
          text: "American"
        },
        {
          letter: "D",
          text: "Frontier"
        }
      ],
      answer: "B"
    },
    {
      questionText: "Why is Bingo the way that he is?",
      code: "B4LL5", // done
      points: 8,
      options: [
        {
          letter: "A",
          text: "A lifetime of abuse"
        },
        {
          letter: "B",
          text: "An attrocious face and also everything else about him"
        },
        {
          letter: "C",
          text: "Mental deficiencies"
        },
        {
          letter: "D",
          text: "All of the above"
        }
      ],
      answer: "D"
    },
    {
      questionText: "After the 2023 Detroit Gambler, how many running vehicles did Bingo have available with more than 2 seats?",
      code: "POO5T1CK", // done
      points: 4,
      options: [
        {
          letter: "A",
          text: "1"
        },
        {
          letter: "B",
          text: "2"
        },
        {
          letter: "C",
          text: "3"
        },
        {
          letter: "D",
          text: "0"
        }
      ],
      answer: "A"
    },
    
  ]
}