import './RulesList.scss';

export default function RulesList() {
    return (
        <div className="rules-list">
            <h4>
                Rules
            </h4>
            <ul>
                <li>1. Bingo may not answer.</li>
                <li>2. When point thresholds are reached, Bingo may receive a present (at the discretion of the arbiter).</li>
                <li>3. Points will be deducted for incorrect answers.</li>
                <li>4. Bingo sucks.</li>
            </ul>
        </div>
    )
}