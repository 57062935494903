import './QuestionOption.scss';

function QuestionOption({ onClick, option }) {
  return (
    <div className="question-option" onClick={onClick.bind(this, option)}>
      <p>
        <span>{option.letter}.</span> {option.text}</p>
    </div>
  );
}

export default QuestionOption;
