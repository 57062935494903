import './CodeInput.scss';

function CodeInput({inputUpdated}) {
    return (
        <div className="code-input">
            <h3>
                Input Code
            </h3>
            <form>
                <input type="text" onKeyUp={inputUpdated} />
            </form>
        </div>
      );
}

export default CodeInput;